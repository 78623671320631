export const BASEURL =
 "https://api.healon.ai/";
//  'http://127.0.0.1:8000/';
 // "https://backoffice.healon.care/";
 //  "https://devadmin.nursefam.care/";

export const EXTERNAL_BASEURL = "";

export const UPLOAD_IMAGE = "";
export const PACKAGE =
  "findcare/standard-package?healonuser=true&healon_services_type=";
export const PACKAGE_DETAILS = "findcare/package-details?package_id=";
export const CREATE_PACKAGE = "findcare/create-packages";
export const CART = "findcare/add-items-cart";
export const ORDERS = "findcare/orders";
export const CREATE_ORDER = "findcare/create-order";
export const ADDRESS = "findcare/customer-address";
export const USER_DETAILS = "userdetails/?user_type=";
export const PATCH_USER_DETAILS = "userdetails/";
export const ADD_MEMBER = "findcare/add-member";
export const PARTNERS = "findcare/get-modal-data";
export const PARTNER_CASES = "findcare/partner-cases";
export const PARTNER_DETAILS = "partner/partner-details";
export const PARTNER_STATUS = "/user-state";
export const PARTNER_PREFERENCE = "/partner/partner-casepreferences";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export const SERVICE_TYPES =
  "findcare/package-service-location?healonuser=true";
export const SERVICE_TYPE =
  "findcare/healon-services-types?healonuser=true&id=";
//////////////////////////////////////////Shop ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export const SHOP_DETAILS = "shop/category-list";
export const PRODUCT_DETAILS = "shop/create-product";
export const CREATE_SHOP_ORDER = "shop/product-orders-create";
export const BUSINESS_PROFESSION = "shop/get-business-profession-types";
export const VENDOR_SIGNUP = "coms/signup/";
export const CREATE_BUSINESS_DETAILS = "shop/create-business-details";
export const VENDOR_LOGIN = "coms/signin/";
export const PRODUCTS = "shop/create-product";
export const PRODUCT_CATEGORY = "shop/product-category";
export const SEARCH_PRODUCTS = "shop/search-products?product_name=";
